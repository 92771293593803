
import { useSearchRouter } from "@/plugins/search";
import {
  ClickEvent,
  collectParams,
  findFormParent,
  FormData,
  SubmitEvent,
  UniverseQuery,
  unwrap,
} from "@/util";
import { defineComponent } from "vue";

interface CharacterQuery extends UniverseQuery, FormData {
  characterSearchString: string;
}

export default defineComponent({
  props: ["podSelect", "query"],
  setup() {
    const sRouter = useSearchRouter();

    function query(f: HTMLFormElement) {
      const input = collectParams(f) as CharacterQuery;
      sRouter.goSearch("character", {
        uid: input.podSelect,
        query: input.characterSearchString,
      });
    }

    function submit(e: ClickEvent) {
      query(unwrap(findFormParent(e.target as HTMLAnchorElement)));
    }

    function search(e: SubmitEvent) {
      query(e.target);
    }

    return { submit, search };
  },
});
