<template>
  <form
    id="SearchCharacterForm"
    @submit.prevent="search"
    action="/nexus-hq/character/search"
    method="post"
  >
    <div class="t-list">
      <div class="t-row seethrough">
        <b><i></i></b><i><b></b></i><em></em>
        <div class="t-cell">
          <div id="CharacterIcon" class="icon"></div>
        </div>
        <div class="t-cell searchField">
          <h3>Character</h3>
          <input
            id="CharacterSearchString"
            name="characterSearchString"
            type="text"
            class="required noSpecialCharsCharacters"
            minlength="3"
            maxlength="24"
            placeholder="Enter Character Name"
            :value="query"
          />

          <input
            :value="podSelect"
            id="PodSelectCharacter"
            name="podSelect"
            type="hidden"
          />
        </div>
        <div class="t-cell">
          <a
            href="#"
            @click.prevent="submit"
            id="SearchForCharactersButton"
            class="button alert"
            ><span>Search</span></a
          >
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { useSearchRouter } from "@/plugins/search";
import {
  ClickEvent,
  collectParams,
  findFormParent,
  FormData,
  SubmitEvent,
  UniverseQuery,
  unwrap,
} from "@/util";
import { defineComponent } from "vue";

interface CharacterQuery extends UniverseQuery, FormData {
  characterSearchString: string;
}

export default defineComponent({
  props: ["podSelect", "query"],
  setup() {
    const sRouter = useSearchRouter();

    function query(f: HTMLFormElement) {
      const input = collectParams(f) as CharacterQuery;
      sRouter.goSearch("character", {
        uid: input.podSelect,
        query: input.characterSearchString,
      });
    }

    function submit(e: ClickEvent) {
      query(unwrap(findFormParent(e.target as HTMLAnchorElement)));
    }

    function search(e: SubmitEvent) {
      query(e.target);
    }

    return { submit, search };
  },
});
</script>
