<template>
  <div class="column-row">
    <div
      id="TopBanner"
      class="column column-large column-background first last"
    >
      <div class="column-content">
        <h1>Nexus HQ - BETA</h1>
        <p>
          We are striving to bring players the best experience possible so be
          sure to check back in to see our regular updates and awesome
          features!!
        </p>
      </div>
    </div>
  </div>
</template>