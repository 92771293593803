export function unwrap<T>(v: T | undefined | null): T {
    if (v === undefined || v === null) {
        throw new Error("panic: unwrap called on undefined");
    } else {
        return v;
    }
}

export function collectParams(f: HTMLFormElement): FormData {
    var obj: FormData = {};
    for (var i = 0; i < f.length; i++) {
        const a = f[i] as HTMLInputElement;
        obj[a.name] = a.value;
    }
    return obj;
}

export function findFormParent(e: HTMLElement): HTMLFormElement | null {
    let f: HTMLElement | null = e;
    while (f != null && !(f instanceof HTMLFormElement)) {
      f = f.parentElement;
    }
    return f;
}

export interface SubmitEvent extends Event {
    target: HTMLFormElement;
}

export interface ClickEvent extends Event { }

export interface FormData {
    [k: string]: string;
}
export interface UniverseQuery {
    podSelect: string;
}