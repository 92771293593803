
import { computed, defineComponent, inject } from "vue";
import NexusHQBeta from "@/components/modules/NexusHQBeta.vue";
import CharacterSearchForm from '@/components/community/CharacterSearchForm.vue';
import { useRoute } from "vue-router";
import { Search } from "@/plugins/search";
import { unwrap } from "@/util";
import { UNIVERSE_DICT } from "@/data/universe";
import { CharacterKey } from "@/data/characters";

export default defineComponent({
  components: {
    "nexus-hq-beta": NexusHQBeta,
    CharacterSearchForm,
  },
  setup() {
    const search: Search = unwrap(inject("search"));
    const route = useRoute();
    const uId = computed(() => Number(route.params["uid"]));
    const universe = computed(() => UNIVERSE_DICT.get(uId.value));
    const query = computed(() => String(route.params["query"]));
    const uSearch = computed(() => unwrap(search.characters.get(uId.value)));
    const fieldResults = computed(() => uSearch.value.doc.search(query.value));
    const results = computed(() => {
      let set: Set<number> = new Set();
      let arr: CharacterKey[] = [];
      let refs: CharacterKey[] = uSearch.value.refs;
      fieldResults.value.forEach((x) => {
        //console.log("x", x);
        x.result.forEach((y) => {
          //console.log("y", y);
          const id = y as number;
          if (!set.has(id)) {
            const r = refs[id];
            //console.log("r", r);
            set.add(id);
            arr.push(r);
          }
        });
      });
      console.log("a", arr);
      return arr;
    });
    function getUser(k: string) {
      return universe.value.characters.get(k);
    }
    return { results, query, uId, getUser, universe };
  },
});
