<template>
  <div>
    <nexus-hq-beta></nexus-hq-beta>
    <text-module title="Search" id="SingleSearchBar">
      <h3 class="right-heading">{{universe.name}}</h3>
      <character-search-form :podSelect="$route.params.uid" :query="$route.params.query"></character-search-form>
    </text-module>

    <text-module>
      <div class="negative-row">
        <div class="column-round round-heading">
          <b><i></i></b><i><b></b></i><em></em>
          <div class="column-round-body">
            <div id="FoundItems">{{ results.length }} Character(s) Found</div>
            <h1>Search Result</h1>
          </div>
        </div>

        <p class="column-navigation">
          &shy;
          <a
            id="ContentPlaceHolderUniverse_HyperLinkLeft"
            class="play-left update-url"
            rel="page=1"
            href=""
            @click.prevent=""
            >&laquo;</a
          >
          1&nbsp; of&nbsp; 1

          <a
            id="ContentPlaceHolderUniverse_HyperLinkRight"
            class="play-right update-url"
            rel="page=1"
            href=""
            @click.prevent=""
            >&raquo;</a
          >

          <label for="show"> Show</label>
          <select id="show" name="show" class="update-url">
            <option value="10" selected>10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
        </p>

        <!--<div id="ContentPlaceHolderUniverse_NoItems" class="noItems">
              <h3>No items found</h3>
            </div>-->

        <div class="t-list innerHeight" id="CharacterList">
          <div class="t-row" v-for="char in results" :key="char.key">
            <b><i></i></b><i><b></b></i><em></em>
            <div class="t-cell t-first">
              <h3>
                <router-link
                  :to="{
                    name: 'nexus-hq-character-detail',
                    params: { user: char.key, universe: char.universe },
                  }"
                  >{{ char.name }}</router-link
                >
              </h3>
            </div>
          </div>
        </div>
      </div>
    </text-module>
  </div>
</template>

<style lang="scss" scoped>
#CharacterList {
  .t-row {
    height: 50px;
    padding-top: 20px;
  }

  h3 a {
    padding: 10px 20px;
    margin-top: 20px;

    &:hover {
      color: #3300bb;
    }
  }
}
</style>

<script lang="ts">
import { computed, defineComponent, inject } from "vue";
import NexusHQBeta from "@/components/modules/NexusHQBeta.vue";
import CharacterSearchForm from '@/components/community/CharacterSearchForm.vue';
import { useRoute } from "vue-router";
import { Search } from "@/plugins/search";
import { unwrap } from "@/util";
import { UNIVERSE_DICT } from "@/data/universe";
import { CharacterKey } from "@/data/characters";

export default defineComponent({
  components: {
    "nexus-hq-beta": NexusHQBeta,
    CharacterSearchForm,
  },
  setup() {
    const search: Search = unwrap(inject("search"));
    const route = useRoute();
    const uId = computed(() => Number(route.params["uid"]));
    const universe = computed(() => UNIVERSE_DICT.get(uId.value));
    const query = computed(() => String(route.params["query"]));
    const uSearch = computed(() => unwrap(search.characters.get(uId.value)));
    const fieldResults = computed(() => uSearch.value.doc.search(query.value));
    const results = computed(() => {
      let set: Set<number> = new Set();
      let arr: CharacterKey[] = [];
      let refs: CharacterKey[] = uSearch.value.refs;
      fieldResults.value.forEach((x) => {
        //console.log("x", x);
        x.result.forEach((y) => {
          //console.log("y", y);
          const id = y as number;
          if (!set.has(id)) {
            const r = refs[id];
            //console.log("r", r);
            set.add(id);
            arr.push(r);
          }
        });
      });
      console.log("a", arr);
      return arr;
    });
    function getUser(k: string) {
      return universe.value.characters.get(k);
    }
    return { results, query, uId, getUser, universe };
  },
});
</script>